@use "sass:math";
@import "../constants";
@import "../mixins/svgiconMixins";

.productAvailability {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    user-select: none;
    transition-duration: $transition-duration;
    @media (min-width: $screen-600px) {
        min-height: 3rem;
    }
}

.productAvailability__stock {
    color: var(--sf-text-color);
    line-height: 1.25rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-radius: var(--sf-border-radius);
    box-shadow: 0 0 0 2px transparent;
    transition-property: background-color, box-shadow;
    transition-duration: $transition-duration;

    &:not(:last-of-type) {
        margin-bottom: 2px;
    }

    &--ableToOrder {
        color: var(--sf-availability-label-able-to-order-text-color);
    }

    &--available {
        color: var(--sf-availability-label-available-text-color);
    }

    &--onRequest {
        color: var(--sf-availability-label-on-request-text-color);
    }

    &--red {
        color: var(--sf-availability-label-red-text-color) !important;
    }

    &--orange {
        color: var(--sf-availability-label-orange-text-color) !important;
    }

    &--green {
        color: var(--sf-availability-label-green-text-color) !important;
    }

    &--teal {
        color: var(--sf-availability-label-teal-text-color) !important;
    }

    &--blue {
        color: var(--sf-availability-label-blue-text-color) !important;
    }

    &--grey {
        color: var(--sf-text-color) !important;
    }
}

.productAvailability__icon {
    width: 32px;
    //height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    picture {
        display: contents;
    }

    img {
        object-fit: contain;
        width: 32px;
        height: 32px;
    }

    .svgicon {
        @include svgicon-color(currentColor);
    }
}

.productAvailability__stockParts {
    padding: 0 8px;
}

.productAvailability__label,
.productAvailability__shopLabel {
    strong, b {
        font-weight: var(--sf-font-weight-bold);
    }
}

.productAvailability__shopLabel {
    color: var(--sf-text-color);
}

.productAvailability__subLabel {
    font-weight: var(--sf-font-weight-normal);
    color: var(--sf-availability-label-sub-label-text-color);
    strong, b {
        font-weight: var(--sf-font-weight-bold);
    }
}