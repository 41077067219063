@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/svgiconMixins";
@import "../mixins/buttonLinkMixins";


.licensePlateSearch {

}

.licensePlateSearch__wrapper {
    display: flex;
    align-items: center;
    user-select: none;
    flex-wrap: wrap;
    padding: 12px;
    background-color: var(--sf-page-part-vehicle-search-background-color);
    color: var(--sf-page-part-vehicle-search-text-color);
    border-radius: var(--sf-border-radius);
    gap: 12px 32px;
    justify-content: center;
    flex-direction: column;
    @media (min-width: $screen-600px) {
        flex-direction: row;
        justify-content: flex-start;
    }
    &.--withoutPadding {
        padding: 0;
    }
}

.licensePlateSearch__heading {
    //flex-basis: calc(37.5% - 8px);
    flex-shrink: 0;
    white-space: nowrap;
    order: 2;
    color: var(--sf-page-part-vehicle-search-text-color);
}

.licensePlateSearch__form {
    order: 0;
    display: flex;
    padding: 0;
    box-sizing: border-box;
    justify-content: center;
    align-items: stretch;
    border-radius: var(--sf-license-plate-border-radius);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .5), var(--sf-block-shadow);
    background-color: white;
    background-image: linear-gradient(200deg, rgba(0, 0, 0, 0.03), rgba(255, 255, 255, 0.1));
    aspect-ratio: 5/1;
    max-height: var(--sf-license-plate-height);
    max-width: 100%;

    &.--NL {
        background-color: var(--sf-color-eu-yellow) !important;
    }
}

.licensePlateSearch__label {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    height: 100%;
}

.licensePlateSearch__licensePlateEU {
    background-color: var(--sf-color-eu-blue);
    color: white;
    font-weight: var(--sf-font-weight-bold);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: calc(var(--sf-license-plate-border-radius) - 2px);
    font-size: calc(var(--sf-license-plate-height) / 3.5);
    width: 17%;
    overflow: hidden;
    line-height: 1;
    //noinspection CssOverwrittenProperties
    margin: 2px;
    //noinspection CssOverwrittenProperties
    margin: unquote("round(max(calc(var(--sf-license-plate-height) * 0.06), 2px), 1px)");

    .svgicon {
        color: var(--sf-color-eu-yellow);
        @include svgicon-size(calc(var(--sf-license-plate-height) / 2.5));
    }

    &.--GB {
        color: var(--sf-color-eu-yellow);
    }

    img {
        display: block;
        border-radius: calc(var(--sf-border-radius) - 1px);
        max-width: 80%;
        -webkit-user-drag: none;
    }
}

input[type=text].licensePlateSearch__input {
    line-height: var(--sf-license-plate-height);
    height: 100%;
    font-size: calc(var(--sf-license-plate-height) * 0.6) !important;
    background: transparent none;
    //border-radius: 0 !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    letter-spacing: .05em;
    text-align: center;
    font-weight: var(--sf-font-weight-bold) !important;
    padding: 0 !important;
    text-transform: uppercase;

    &:focus {
        background-color: transparent !important;
    }

    &::placeholder {
        font-weight: var(--sf-font-weight-bold) !important;
        text-transform: none;
        margin: 0 !important;
        letter-spacing: .05em;
        text-align: center;
    }
}

.licensePlateSearch__btn {
    @include btnDefault();
    height: 100% !important;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    line-height: 1;
    color: var(--sf-form-control-text-color) !important;
    width: 24px;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 8px !important;

    .svgicon {
        transform: translateY(-1px);
        opacity: 1;
        @include svgicon-size(12px);
    }
}

// Specificity workaround
@media (hover) {
    .licensePlateSearch__wrapper {
        button.licensePlateSearch__btn:hover, button.licensePlateSearch__btn:focus, button.licensePlateSearch__btn.focus {
            color: var(--sf-form-control-text-color) !important;

            .svgicon {
                opacity: 1;
            }
        }
    }
}