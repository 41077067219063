@import "../constants";
@import "../mixins/blockButtonMixins";


@keyframes carretBlink {
    from, to {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.vehicleSearch {
    display: block;
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-page-part-vehicle-search-background-color);
    color: var(--sf-page-part-vehicle-search-text-color);
    padding: 8px;
    flex-grow: 1;
    &.--withoutPadding {
        padding: 0;
    }
}

.vehicleSearch__wrapper {
    display: flex;
    align-items: center;
    user-select: none;
    flex-wrap: wrap;
    gap: 8px 16px;
    @media (min-width: $screen-tablet) {
        flex-wrap: nowrap;
    }
}

.vehicleSearch__heading {
    flex-basis: calc(25% - 16px);
    flex-shrink: 0;
    white-space: nowrap;
    color: var(--sf-page-part-vehicle-search-text-color);
}

.vehicleSearch__articleLink {
    white-space: nowrap;
    order: 4;
    @media (min-width: $screen-480px) {
        margin-inline-start: auto;
        order: initial;
    }
}

.vehicleSearch__charCounter {
    opacity: 0;
    &.--active {
        opacity: .5;
    }
}

.vehicleSearch__form {
    direction: ltr;
    flex-basis: $screen-760px;
    margin: 0;
    flex-shrink: 1;
    order: 3;
    @media (min-width: $screen-tablet) {
        order: 2;
    }
    @media (min-width: $screen-desktop) {
        order: initial;
        flex-basis: 50%;
        flex-shrink: 0;
    }
    flex-grow: 0;
    height: 3rem;
    display: flex;
    box-sizing: content-box;
    justify-content: center;
    align-items: stretch;
    border-radius: var(--sf-border-radius);
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: white;
    background-clip: content-box;
    --vehicleSearch__char-border-color: var(--sf-line-color-strong);

    &.--active {
        --vehicleSearch__char-border-color: var(--sf-form-control-focus-border-color);
        @include blockButton--current(var(--sf-form-control-focus-border-color));

        &.--ok {
            --vehicleSearch__char-border-color: #{$color-green};
            @include blockButton--current($color-green);
        }
    }
}

.vehicleSearch__label {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
    position: relative;
    cursor: text;
    user-select: text;
}

.vehicleSearch__char {
    background-image: linear-gradient(to top, var(--vehicleSearch__char-border-color) 0, var(--vehicleSearch__char-border-color) 100%);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 1px 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--ksfdef-font-family-monospace-system-stack), monospace;
    flex-basis: 100%;
    font-size: var(--sf-heading-3-font-size);
    color: var(--sf-form-control-text-color);

    &.--long {
        font-size: $font-size-110rem;
        @media (max-width: $screen-tablet-1) {
            font-size: 1rem;
            background-image: linear-gradient(to top, var(--vehicleSearch__char-border-color) 6px, transparent 6.5px);
        }
    }
}

.vehicleSearch__carret {
    height: 24px;
    width: 2px;
    border-radius: 2px;
    display: none;
    background-color: $color-red;
    animation-name: carretBlink;
    animation-duration: 1s;
    animation-timing-function: step-end;
    animation-iteration-count: infinite;

    .vehicleSearch__form.--active & {
        display: block;
    }

    .vehicleSearch__form.--ok & {
        background-color: $color-green;
    }
}

.vehicleSearch__input[type=text] {
    opacity: 1;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 !important;
    height: 100% !important;
    color: transparent !important;
    caret-color: transparent !important;
    text-shadow: none !important;
    outline: none !important;
    -webkit-appearance: none !important;

    &, &:focus {
        background: transparent none !important;
        box-shadow: none !important;
        border: none !important;
    }

    &::-ms-clear,
    &::-ms-reveal,
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
        -webkit-appearance: none;
    }
}

.vehicleSearch__btn {
    border-radius: 0;
    border: none;
    height: 100%;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    line-height: 1;
    padding: 0 8px * 1.5;
    color: var(--sf-text-color);
    cursor: not-allowed;

    .svgicon {
        opacity: .5;
        transform: scale(.75) translateY(-1px);
    }

    .vehicleSearch__form.--ok & {
        cursor: pointer;
        color: $color-green;

        .svgicon {
            opacity: 1;
        }
    }

    .vehicleSearch__form.--active.--ok & {
        background-color: $color-green;
        color: white;

        .svgicon {
            opacity: 1;
        }
    }
}

.vehicleSearch__kba {
    position: relative;
    display: grid;
    gap: 8px 24px;
    user-select: none;
    @media (min-width: $screen-tablet) {
        display: flex;
    }
}

.vehicleSearch__kba__heading {
    text-align: center;
    flex-shrink: 1;
    color: inherit;
    display: flex;
    align-items: center;
    height: var(--sf-btn-xs-height);
    @media (min-width: $screen-tablet) {
        height: var(--sf-form-control-height);
        order: 1;
    }
}

.vehicleSearch__kba__infoBtn {
    flex-shrink: 0;
    margin-inline-start: auto;
    order: 2;
    font-weight: var(--sf-font-weight-normal);
    position: absolute;
    right: 0;
    top: 0;
    @media (min-width: $screen-tablet) {
        top: var(--sf-form-control-height);
    }
}

.vehicleSearch__kba__form {
    display: flex;
    gap: 8px;
    flex-basis: 480px;
}

.vehicleSearch__kba__label {
    flex-grow: 1;
    text-align: center;

    span {
        opacity: .5;
        font-size: $font-size-088rem;
    }

    &:nth-child(1) {
        flex-basis: 40%;
    }

    &:nth-child(2) {
        flex-basis: 30%;
    }
}

.vehicleSearch__kba__input {
    text-align: center;
    letter-spacing: .2em;
    font-size: calc(var(--sf-form-control-height) * 0.6) !important;

    &::placeholder {
        font-size: 1rem !important;
        letter-spacing: var(--sf-letter-spacing);
    }
}

.vehicleSearch__kba__searchBtn {
    @media (min-width: $screen-520px-1) {
        flex-grow: 1;
    }

    .vehicleSearch__kba__searchBtnText {
        @media (max-width: $screen-520px-1) {
            display: none;
        }
    }
}